function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p',[_vm._v("Magna consequat magna incididunt in. Pariatur sit velit pariatur cupidatat proident sint occaecat excepteur adipisicing. Reprehenderit mollit sunt velit dolore reprehenderit duis velit nulla est sunt sunt. In non tempor esse cillum mollit tempor laborum nisi labore elit. Deserunt duis Lorem consectetur ut ut incididunt consectetur ad minim occaecat nulla sint cupidatat.")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }